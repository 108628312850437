<template>
  <div id="region-page" class="container mx-auto bg-white">
    <div class="md:py-10 flex flex-col px-4 py-6">
      <section class="hero grid order-1 md:gap-x-16 md:grid-cols-[1fr_min(calc(50%-64px),624px)] md:grid-rows-[auto,1fr] md:-mb-13">
        <div class="group-1 order-1">
          <div class="headline overflow-visible">
            <h1 class="text-2xl md:text-3xl">{{ $t('log_in_slogan_title') }}</h1>
            <h2 class="text-lg mb-0 mt-4 font-400">{{ region?.web_subtitle }}</h2>
          </div>
          <client-only>
            <search-bar v-if="isShowSearchBar" class="mt-10" @search="handleSearch" />
            <template #fallback>
              <n-skeleton class="flex items-center gap-3 h-16 rounded mt-10"></n-skeleton>
            </template>
          </client-only>
        </div>
        <div class="order-2 row-span-2 hidden my-5 md:flex md:-mt-5">
          <nd-img src="/images/main-theme.png" alt="main-theme" class="object-contain w-full" />
        </div>
        <div class="greeting overflow-hidden md:mt-50px md:mr-21 md:col-span-2 order-3">
          <p class="my-0 text-lg truncate">{{ region?.web_greetings }}</p>
        </div>
      </section>
      <section v-if="region?.banner" class="banner order-2 -mt-4 md:mt-6">
        <carousel loop autoplay navigator :data="region?.banner" :autoplay-delay="2000">
          <template #default="{ item }">
            <a
              v-gtm:click="{ event: GtmEvent.HOME_BANNER_CLICKED, payload: { banner: item } }"
              class="block w-[calc(100dvw-40px)] md:w-360px"
              :href="item.link_url"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="banner-image h-135px overflow-hidden rounded">
                <nd-img :src="`fn:${item.cover_image}`" :alt="item.title" class="object-cover w-full h-full" />
              </div>
              <p class="banner-description text-base mt-3 w-full h-11 line-clamp-2">
                {{ item.description }}
              </p>
            </a>
          </template>
        </carousel>
      </section>
      <section
        v-if="region?.category"
        class="categories no-scrollbar overflow-auto order-3 border-solid border-gray-40 border-b pb-6 pt-2"
        md="mt-10 pb-0 py-10 border-b-0 border-t"
      >
        <div
          class="categories-list inline-flex flex-wrap gap-x-1 gap-y-6 w-fit h-176px sm:flex-row md:w-1248px md:h-auto md:gap-12"
          :class="isCategoriesEnough ? 'flex-col' : 'flex-row'"
        >
          <template v-for="item in region?.category" :key="item.name">
            <locale-link class="category w-20 pt-2 pb-1 flex flex-col items-center gap-2" :to="`/regions/${regionId}/categories/${item.id}`">
              <div class="category-image w-8 h-8 md:w-16 md:h-16 md:p-2">
                <nd-img :src="`fn:${item.icon_image || item.cover_image}`" :alt="item.name" class="object-cover" />
              </div>
              <div class="category-name">
                <p class="text-base text-center line-clamp-1">{{ item.name }}</p>
              </div>
            </locale-link>
          </template>
        </div>
      </section>
      <section v-if="themesGroupList.length" class="themes order-4 mt-4 md:mt-10">
        <carousel :title="$t('region_theme_title')" navigator :data="themesGroupList">
          <template #default="{ item }">
            <div class="flex flex-col gap-x-5 gap-y-4">
              <div v-for="theme in item" :key="theme.name" class="theme relative overflow-hidden rounded">
                <locale-link :to="`/regions/${regionId}/themes/${theme.id}`">
                  <nd-img class="w-233px h-146px object-cover" :src="`fn:${theme.cover_image}`" :alt="theme.name" />
                  <div class="mask bg-opacity-40 absolute top-0 left-0 flex items-center justify-center w-full h-full bg-black-80">
                    <p class="text-center text-lg text-white line-clamp-2 px-5">{{ theme.name }}</p>
                  </div>
                </locale-link>
              </div>
            </div>
          </template>
        </carousel>
      </section>
      <section v-if="popularProductsGroupList.length" class="hottest-item md:mt-12 order-5 mt-6">
        <carousel
          :title="$t('region_popular_title')"
          navigator
          action-button
          :data="popularProductsGroupList"
          @click-action-button="goToProductList(ModeQueryStringEnum.POPULAR_LIST_KEY)"
        >
          <template #default="{ item }">
            <div class="flex flex-col gap-8">
              <product-card v-for="productId in item" :key="productId" class="w-297px" :productId="productId" />
            </div>
          </template>
        </carousel>
      </section>
      <section v-if="newestProductsGroupList.length" class="newest-item order-6 mt-6">
        <carousel
          :title="$t('region_new_arrival_title')"
          navigator
          action-button
          :data="newestProductsGroupList"
          @click-action-button="goToProductList(ModeQueryStringEnum.NEWEST_LIST_KEY)"
        >
          <template #default="{ item }">
            <div class="flex flex-col gap-8">
              <product-card v-for="productId in item" :key="productId" class="w-297px" :productId="productId" />
            </div>
          </template>
        </carousel>
      </section>
      <section v-if="region?.event_news?.length" class="news order-7 mt-6 md:mt-12">
        <carousel :title="$t('region_news_title')" navigator :data="uiEventNews">
          <template #default="{ item }">
            <a :href="item.link_url" target="_blank" rel="noopener noreferrer">
              <div class="news-card">
                <div class="news-image w-80 h-178px rounded overflow-hidden md:w-360px md:h-212px">
                  <nd-img :src="`fn:${item.cover_image}`" :alt="item.title" class="object-cover w-full h-full" />
                </div>
                <p class="news-title mt-3 mb-0 text-xl w-80 md:w-360px line-clamp-2">{{ item.title }}</p>
              </div>
            </a>
          </template>
        </carousel>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NSkeleton } from 'naive-ui'
import SearchBar from '@/components/search/search-bar.vue'
import ProductCard from '@/components/product/product-card.vue'
import Carousel from '@/components/common/carousel.vue'

import { useAppStore } from '@/store/app'
import { useRegionStore } from '@/store/region'
import { useProductStore } from '@/store/product'

import { getProducts } from '@/service/product'
import { ProductTagIdEnum, SortRuleEnum } from '@/types/product'
import { GtmEvent } from '@/types/gtm'
import { ModeQueryStringEnum } from '@/types/filters'

definePageMeta({
  middleware: ['check-region'],
})

const EVENT_NEWS_LIMIT = 5

const router = useLocaleRouter()
const app = useAppStore()
const regionStore = useRegionStore()
const productStore = useProductStore()

const isCategoriesEnough = computed(() => (region.value?.category?.length || 0) > 4)
const isShowSearchBar = computed(() => app.isDesktopScreen)

const { regionId } = useRegion()
const popularProducts = computed(() => productStore.listIds.popularProductGroup)
const newestProducts = computed(() => productStore.listIds.newestProductGroup)

const region = computed(() => regionStore.regionInfo)

await useAsyncData(
  async () => {
    if (typeof regionId.value !== 'number') return []

    const popularProducts = await regionStore.fetchRegionPopular(regionId.value)

    productStore.handleProducts('popularProductGroup', popularProducts)

    return popularProducts
  },
  { watch: [regionId], server: false }
)

const popularProductsGroupList = computed(() => {
  if (!popularProducts.value) return []

  return chunking(popularProducts.value, 2)
})

await useAsyncData(
  async () => {
    if (typeof regionId.value !== 'number') return []

    const { data: newestProducts } = await getProducts({
      filter_regionid: regionId.value,
      filter_tags: nestedArrToString([[ProductTagIdEnum.PRODUCT_NEW_RELEASE]]),
      sortby: SortRuleEnum.NEW_RELEASE,
      size: 16,
    })

    productStore.handleProducts('newestProductGroup', newestProducts)

    return newestProducts
  },
  { watch: [regionId], server: false }
)

const newestProductsGroupList = computed(() => {
  if (!newestProducts.value) return []

  return chunking(newestProducts.value, 2)
})

const themesGroupList = computed(() => {
  if (!region.value?.theme) return []

  return chunking(region.value.theme, 2)
})

const uiEventNews = computed(() => {
  return region.value?.event_news?.slice(0, EVENT_NEWS_LIMIT) || []
})

const goToProductList = (mode: string) => {
  router.push({ path: `/regions/${regionId.value}/search`, query: { mode } })
}

const handleSearch = (keyword: string) => {
  useNuxtApp().$trackEvent({
    event: GtmEvent.SEARCH_QUERY_SENT,
    payload: {
      keyword: keyword,
    },
  })
  router.push({ path: `/regions/${regionId.value}/search`, query: { keyword: keyword } })
}
</script>

<style lang="scss" scoped>
#region-page {
  min-height: var(--content-min-height);
}
</style>
