<template>
  <n-popover trigger="manual" :show="showQuickSearchHint" :show-arrow="false" placement="bottom-start" @clickoutside="handleClickOutside">
    <template #trigger>
      <div class="search-bar flex items-center gap-3 p-3 rounded" v-bind="$attrs" @click="handleClickSearchBar">
        <input
          ref="inputRef"
          v-model="keyword"
          class="flex-1 border-0 ml-2 text-lg caret-yellow-50 outline-none"
          :placeholder="$t('all_search_placeholder')"
          @keydown.enter="handleSearch"
          v-on="events"
        />
        <nd-button type="primary" class="!p-2" @click.stop="handleSearch">
          <svg-icon name="search" class="w-6 h-6 text-white" />
        </nd-button>
      </div>
    </template>
    <template #default>
      <div class="search-hint-wrapper">
        <search-hint @click-keyword="handleClickKeyword" />
      </div>
    </template>
  </n-popover>
</template>

<script setup lang="ts">
import { NPopover } from 'naive-ui'

import SearchHint from '@/components/search/search-hint.vue'

const searchRecord = useSearchRecord()
const { isComposing, events } = useCompositionEvent()

const emit = defineEmits(['search'])

const inputRef = ref<Nullable<HTMLInputElement>>(null)
const showQuickSearchHint = ref(false)
const keyword = ref('')

const handleClickSearchBar = () => {
  showQuickSearchHint.value = true
  if (inputRef.value) inputRef.value.focus()
}
const handleClickOutside = () => {
  showQuickSearchHint.value = false
}

const handleSearch = () => {
  if (!keyword.value || isComposing.value) return
  showQuickSearchHint.value = false

  searchRecord.add(keyword.value)
  emit('search', keyword.value)
}

const handleClickKeyword = (selectedKeyword: string) => {
  keyword.value = selectedKeyword
  handleSearch()
}
</script>

<style lang="scss" scoped>
.search-bar {
  box-shadow:
    0px 6px 16px -8px rgba(0, 0, 0, 0.16),
    0px 9px 28px rgba(0, 0, 0, 0.1);
}
.search-hint-wrapper {
  width: 368px;
  @screen lg {
    width: 464px;
  }
  @screen xl {
    width: 624px;
  }
}
</style>
